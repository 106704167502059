import FileSaver from 'file-saver';
import md5 from 'md5';

const Utils = {
  /**
   * Flat Map 根据相应的字断
   * @param arr 源数组
   * @param step 层级
   * @param clear 是否删除原层级数据
   * @returns {[]|*[]}
   */
  flatMap(arr, step = 'children', clear = false) {
    const flatArr = [];
    const iterate = function(item) {
      flatArr.push(item);
      if(item[step]) {
        item[step].forEach(v => {
          iterate(v);
        });
        if(clear) {
          delete item[step];
        }
      }
    };
    if(!arr || !(arr instanceof Array)) {
      return [];
    } else {
      arr.forEach(iterate);
      return flatArr;
    }
  },

  /**
   * unFlat Map 根据相应的字断
   * @param items 扁平数组
   * @param key 父类id key
   * @param parentId 父类id key
   * @param child 子类 key
   * @returns {[]}
   */
  unFlatMap(items, key = 'id', parentId = 'parentId', child = 'children') {
    const result = [];   // 存放结果集
    const itemMap = {};  //
    for(const item of items) {
      const id = item[key];
      const pid = item[parentId];

      if(!itemMap[id]) {
        itemMap[id] = { [child]: [] };
      }

      itemMap[id] = { ...item, [child]: itemMap[id][child] };

      const treeItem =  itemMap[id];

      if(pid === 0) {
        result.push(treeItem);
      } else {
        if(!itemMap[pid]) {
          itemMap[pid] = { [child]: [] };
        }
        itemMap[pid][child].push(treeItem);
      }
    }
    return result;
  },

  params2json(params = '', slice = '&') {
    return params.split(slice).reduce((acc, item) => {
      let arr = item.split('=');
      return item ? { ...acc, ...{ [arr[0]]: arr[1] }} : acc;
    }, {});
  },

  handlerOptions(option, textKey, valueKey) {
    if(!option) throw new Error('option can not be undifend or null');
    const optionProps = {};
    if(typeof option === 'object') {
      optionProps.disabled = option.disabled || false;
      option = {
        [textKey]: option[textKey],
        [valueKey]: option[valueKey]
      };
    } else {
      option = { [textKey]: option, [valueKey]: option };
    }
    return { option, optionProps };
  },
  getFileExt(fileName = '') {
    const index = fileName.lastIndexOf('.');
    const regExp = new RegExp('^[A-Za-z0-9]+$');
    if(index > 0 && regExp.test(fileName.slice(index + 1))) {
      fileName = fileName.toLowerCase();
      return fileName.substring(index + 1);
    }
    return '';
  },
  // 导出文件
  exportFile(url = '', name = '') {
    if(!url) return;
    return FileSaver.saveAs(url, `${`${name}`.trim()}.${Utils.getFileExt(url)}`);
  },

  isNotEmpty(value) {
    switch (typeof value) {
      case 'undefined': {
        return false;
      }

      case 'string': {
        return value.length !== 0;
      }

      case 'object': {
        if(Array.isArray(value)) {
          return value.length !== 0;
        } else if(value === null) {
          return false;
        } else {
          return Object.keys(value).length !== 0;
        }
      }

      default: {
        return true;
      }
    }
  },

  searchAssign(origin = {}, values = {}, ...params) {
    let tempValue = Object.assign({}, values);
    if(params && params.length) {
      params.forEach((item) => {
        Object.assign(tempValue, item);
      });
    }
    if(Object.keys(tempValue).length) {
      for(let key in tempValue) {
        if(this.isNotEmpty(tempValue[key]) && this.isNotEmpty(String(tempValue[key]))) {
          origin[key] = tempValue[key];
        } else {
          delete origin[key];
        }
      }
    } else {
      for(let key in origin) {
        if(!this.isNotEmpty(origin[key])) {
          delete origin[key];
        }
      }
    }
    return origin;
  },

  /**
   * 先过滤对象中的空属性，再合并，使用方法类似于Object.assign，不对第一个参数做修改，而是将结果作为新的对象返回
   * @param args
   * @returns {{}}
   */
  filterMerge(...args) {
    return Object.assign.apply(null, args.map((object) => {
      return Object.keys(object).reduce((obj, key) => {
        const value = object[key];
        if(Utils.isNotEmpty(value) && value !== 'undefined') {
          obj[key] = value;
        }
        return obj;
      }, {});
    }));
  },

  json2params(json, slice = '&') {
    return Object.keys(json).reduce((acc, item) => {
      return String(acc) + item + '=' + json[item] + slice;
    }, '').slice(0, -1);
  },

  formatQuery2QueryStr(query = {}) {
    let queryStr = '';
    const filteredQuery = Utils.filterMerge(query);
    if(Utils.isNotEmpty(filteredQuery)) {
      queryStr = '?' + Utils.json2params(filteredQuery);
    }
    return queryStr;
  },

  /**
   * 链接拼参数
   * @param url 可以是带query的也可以是不带的
   * @param params 拼的参数对象
   * @returns {string}
   */
  padQuery(url = '', params = {}) {
    const [pathname, queryStr] = url.split('?');
    let tempQuery = Utils.filterMerge(Object.assign({}, Utils.params2json(queryStr), Utils.filterMerge(Object.assign({}, params))));
    let searchQuery = Utils.formatQuery2QueryStr(tempQuery);
    return `${pathname}${searchQuery}`;
  },
  // 权限管理处理数据信息
  handleDataInfo(data = [], type = 1) {
    let list = [];
    if(type === 1) {
      data.forEach(item => {
        const infoFirst = {};
        infoFirst.title = item.name;
        infoFirst.key = item.id;
        if(item.childList && item.childList.length > 0) {
          infoFirst.children = [];
          item.childList.forEach(v => {
            const infoTwo = {};
            infoTwo.title = v.name;
            infoTwo.key = v.id;
            infoFirst.children.push(infoTwo);
          });
        }
        list.push(infoFirst);
      });
    } else {
      data.forEach(item => {
        list.push(item.id);
        if(item.childList && item.childList.length > 0) {
          item.childList.forEach(v => {
            list.push(v.id);
          });
        }
      });
    }
    return list;
  },
  getDate(time) {
    if(time && !(+time > 0)) {
      let [tDate, tTime] = time.split(' ');
      tDate = tDate.replace(/[^\d]/g, '/');
      time = tTime ? `${tDate} ${tTime}` : `${tDate}`;
    }
    const d = time ? new Date(time) : new Date();
    const M = d.getMonth() + 1;
    const D = d.getDate();
    const h = d.getHours();
    const m = d.getMinutes();
    const s = d.getSeconds();
    return {
      Y: d.getFullYear(),
      M: M < 10 ? '0' + M : M,
      W: d.getDay(), //周几
      D: D < 10 ? '0' + D : D,
      h: h < 10 ? '0' + h : h,
      min: m < 10 ? '0' + m : m,
      s: s < 10 ? '0' + s : s,
      ms: d.getMilliseconds()
    };
  },
  getSizeByByte(size) {
    if(typeof size !== 'number') {
      size = Number(size);
      // throw Error("Argument Must Be A Number")
    }
    const KBUNIT = 'KB', MBUNIT = 'MB', UNITSIZE = 1024;
    let kb = size / UNITSIZE,
      mb = size / (UNITSIZE * UNITSIZE);
    return mb > 0.01
      ? parseFloat(mb).toFixed(2) + MBUNIT
      : parseFloat(kb).toFixed(2) + KBUNIT;
    // return parseFloat(mb).toFixed(2) + MBUNIT;
  },

  // 获取 rgb 颜色值
  getRgbNum(sColor) {
    if(sColor.length === 4) {
      let sColorNew = '#';
      for(let i = 1; i < 4; i += 1) {
      // 补全颜色值 例如：#eee,#fff等
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
      }
      sColor = sColorNew;
    }
    // 处理六位颜色值
    let sColorChange = [];
    for(let i = 1; i < 7; i += 2) {
    // 核心代码，通过parseInt将十六进制转为十进制，parseInt只有一个参数时是默认转为十进制的，第二个参数则是指定转为对应进制
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)));
    }
    return sColorChange;
  },

  // color: 十六进制颜色值，n：透明度
  colorRgba(color, n) {
    // 十六进制颜色值的正则表达式
    let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;

    let sColor = `${color}`.toLowerCase();
    n = n || 1;
    // 十六进制颜色转换为RGB格式
    if(sColor && reg.test(sColor)) {
      let sColorChange = Utils.getRgbNum(sColor);
      return 'rgba(' + sColorChange.join(',') + ',' + n + ')';
    } else {
      return sColor;
    }
  },

  // 加深或减弱颜色值
  lightDarkenColor(color, num) {
    let colorArr = Utils.getRgbNum(color);
    let sColorChange = [];
    for(let i = 0; i < colorArr.length; i++) {
      let val = colorArr[i] + num;
      if(val < 0) {
        val = 0;
      }
      if(val > 255) {
        val = 255;
      }
      sColorChange.push(val);
    }
    return `rgba(${sColorChange.join(',')}, 1)`;
  },

  // flieId
  getFileId(file = {}) {
    const ext = Utils.getFileExt(file.name);
    const lastModified = Number(new Date(file.lastModified || file.lastModifiedDate || ''));
    return md5(String(ext) + lastModified + file.size);
  }
};

export default Utils;

