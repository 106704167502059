/**
 *  Date    : 2019/11/9
 *  Author  : weiLin
 *  Declare : 对createRouter进行封装，集成 breadcrumb 和 indexRoute 等功能
 */
import React, { useEffect } from 'react';
import { useLocation, useParams, useHistory as useRouterDomHistory } from 'react-router-dom';
import createRouter from '@wtdc/feed-router';
import Utils from '@/utils/utils';
import { useMemoizedFn } from 'ahooks';

/**
 * location change hook
 * @param callback
 */
function useLocationChange(callback) {
  const location = useLocation();
  useEffect(() => {
    callback(location);
  }, [location]);

  return location;
}

function useQuery() {
  const location = useLocation();
  return Utils.params2json(location.search.slice(1));
}

/**
 * 创建主路由的PageHome
 * @param MainPage
 * @returns {function(): function(*): *}
 */
function createMainRouter(MainPage = null) {
  return () => (props) => {
    return props.children || (MainPage ? <MainPage /> : null);
  };
}

/**
 * 需要加全局的 行业 code 的
 * @returns {{replace: (function(*): void), push: (function(*): void)}}
 */
function useHistory() {
  const params = useParams();

  const history = useRouterDomHistory();

  function push(path) {
    return history.push(`/${params.code}${path}`);
  }

  function replace(path) {
    return history.replace(`/${params.code}${path}`);
  }

  return {
    ...history,
    push: useMemoizedFn(push),
    replace: useMemoizedFn(replace)
  };
}

export {
  useLocationChange,
  useLocation,
  useParams,
  useQuery,
  useHistory,
  createRouter,
  createMainRouter
};
