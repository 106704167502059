/**
 *  Date    : 2019/9/4
 *  Author  : weiLin
 *  Declare : redux相关utils
 */

import app from '@wtdc/feed';

/**
 * 获取绑定的actions
 * @param selector
 * @returns {any}
 */
export function useActions(selector) {
  return app.useActions(selector);
}

export function useSelector(selector) {
  return app.useSelector(selector);
}
